import React from "react";
import Layout from "../components/layout";

import { LineChart } from "react-chartkick";
import "chart.js";

class AboutPage extends React.Component {
  render() {
    const chartData = [
      {
        name: "C",
        data: {
          "2004": 2,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 0,
          "2009": 0,
          "2010": 0,
          "2011": 0,
          "2012": 0,
          "2013": 0,
          "2014": 0,
          "2015": 0,
          "2016": 0,
          "2017": 0,
          "2018": 0,
          "2019": 0,
          "2020": 0,
        },
      },
      {
        name: "Java",
        data: {
          "2004": 2,
          "2005": 3,
          "2006": 3,
          "2007": 3,
          "2008": 3,
          "2009": 3,
          "2010": 3,
          "2011": 5,
          "2012": 7,
          "2013": 7,
          "2014": 5,
          "2015": 5,
          "2016": 4,
          "2017": 4,
          "2018": 4,
          "2019": 4,
          "2020": 4,
        },
      },
      {
        name: "C#",
        data: {
          "2004": 0,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 1,
          "2009": 2,
          "2010": 3,
          "2011": 0,
          "2012": 0,
          "2013": 0,
          "2014": 0,
          "2015": 0,
          "2016": 0,
          "2017": 0,
          "2018": 0,
          "2019": 0,
          "2020": 0,
        },
      },
      {
        name: "JavaScript",
        data: {
          "2004": 0,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 0,
          "2009": 0,
          "2010": 0,
          "2011": 3,
          "2012": 5,
          "2013": 6,
          "2014": 6,
          "2015": 7,
          "2016": 5,
          "2017": 5,
          "2018": 5,
          "2019": 5,
          "2020": 5,
        },
      },
      {
        name: "Ruby/Rails",
        data: {
          "2004": 0,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 0,
          "2009": 0,
          "2010": 0,
          "2011": 0,
          "2012": 0,
          "2013": 1,
          "2014": 3,
          "2015": 5,
          "2016": 6,
          "2017": 7,
          "2018": 6,
          "2019": 7,
          "2020": 8,
        },
      },
      {
        name: "AWS",
        data: {
          "2004": 0,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 0,
          "2009": 0,
          "2010": 0,
          "2011": 0,
          "2012": 0,
          "2013": 0,
          "2014": 0,
          "2015": 0,
          "2016": 0,
          "2017": 0,
          "2018": 2,
          "2019": 4,
          "2020": 3,
        },
      },
      {
        name: "React",
        data: {
          "2004": 0,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 0,
          "2009": 0,
          "2010": 0,
          "2011": 0,
          "2012": 0,
          "2013": 0,
          "2014": 0,
          "2015": 0,
          "2016": 0,
          "2017": 0,
          "2018": 2,
          "2019": 4,
          "2020": 2,
        },
      },
      {
        name: "Ember",
        data: {
          "2004": 0,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 0,
          "2009": 0,
          "2010": 0,
          "2011": 0,
          "2012": 0,
          "2013": 2,
          "2014": 3,
          "2015": 1,
          "2016": 0,
          "2017": 0,
          "2018": 0,
          "2019": 0,
          "2020": 0,
        },
      },
      {
        name: "Dart/Flutter",
        data: {
          "2004": 0,
          "2005": 0,
          "2006": 0,
          "2007": 0,
          "2008": 0,
          "2009": 0,
          "2010": 0,
          "2011": 0,
          "2012": 0,
          "2013": 0,
          "2014": 0,
          "2015": 0,
          "2016": 0,
          "2017": 0,
          "2018": 0,
          "2019": 4,
          "2020": 3,
        },
      },
    ];

    return (
      <Layout>
        <h1 class="no-margin-bottom">My Tech Skills Over Time</h1>
        <LineChart data={chartData} />
      </Layout>
    );
  }
}

export default AboutPage;
